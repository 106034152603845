<template>
  <div class="login">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">忘记密码</div>
    <img class="bg" src="../../assets/img/loginbg.png" alt="">

    <form class="login-card">
        <div v-if="type== '1'" class="catalog">
            <img src="../../assets/img/login_icon1.png" alt="">
            <input type="text" name="1" placeholder="用户名">
        </div>
        <div v-else class="catalog">
            <img src="../../assets/img/login_icon1.png" alt="">
            <input type="text" name="2" placeholder="企业名称">
        </div>
        <div class="catalog">
            <img src="../../assets/img/login_icon3.png" alt="">
            <input style="width: 50%" type="text" placeholder="输入验证码">
            <div class="verify">获取验证码</div>
        </div>
        <div class="catalog">
            <img src="@/assets/img/login_icon2.png" alt="">
            <input type="text" placeholder="请输入新密码">
        </div>
        <div class="catalog">
            <img src="@/assets/img/login_icon2.png" alt="">
            <input type="text" placeholder="请再次输入密码">
        </div> 
        <button class="login-btn" type="submit">确认</button>
        <div class="login-footer flex-between">
            <router-link to="/logins" class="blue">现在注册</router-link>
            <router-link to="/passwordForget" class="gray">忘记密码</router-link>
        </div>
    </form>

  </div>
</template>
<script>
import { ref,onMounted  } from 'vue';
import { useRoute } from 'vue-router';
export default {
   setup() {
        let router = useRoute(); //获取到值
        /*1：用户名 2：企业名称*/
        const type = ref('1');
        onMounted(() => {
        console.log("获取到的参数", router.query.type);
        type.value = router.query.type;
        });
       return {
           type
       }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.login {
    width: 100vw;
    overflow-x: hidden;
    .navTitle {
        background: none;
    }
    img.bg {
        width: 100%;
        height: auto;
    }
    .login-card {
        margin: 0 14vw;
        margin-top: 15vw;
        .catalog {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0,0,0,.05);
            padding: 2vw 0;
            img {
                width: 5vw;
                height: auto;
            }
            input {
                background: transparent;
                border: 0;
                margin-left: 2vw;
                flex: 1;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
            }
            .verify {
                color: #ff9700;
                padding: 1vw 2vw;
                border: 1px solid #ff9700;
                border-radius: 1.5vw;
                font-size: 4vw;
            }
            
        }
        .login-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 3vw 6vw;
            border-radius: 7vw;
            border: 0;
            margin-top: 8vw;
            width: 100%;
            letter-spacing: 2vw;
        }   
    
    }
    .login-footer {
        margin-top: 4vw;
        font-size: 4vw;
        .gray {
           color: #666666;
        }
        .blue {
            color: #3399ff
        }
    }
    
}
</style>
